<template>
  <!-- 店铺信息 -->
  <div class="content-wrap">
    <div class="bold-title">店铺信息</div>
    <div class="content-info">
      <el-row>
        <el-col :span="5">
          <el-form :model="details">
            <el-form-item prop="iconUrl">
              <div v-if="Editor" style="text-align: center;">
                <base-upload v-model="form.photo" fileType="single" tips="图片尺寸800PX×800PX以上，文件大小在5MB以内,图片格式只支持：JPEG、JPG、PNG" @beforeUpload="handleBeforeUpload"></base-upload>
              </div>
              <div class="storeImg" v-else style="text-align: center;"><el-image :src="details.photo" width="268px"></el-image></div>
            </el-form-item>
            <el-form-item prop="status" v-if="false">
              <el-select v-model="form.status" :disabled="!Editor">
                <el-option label="营业" :value="1"></el-option>
                <el-option label="停业" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="10">
          <el-form :model="details" label-width="200px" v-show="!Editor">
            <el-form-item label="店铺名称：" prop="name" label-width="120px">
              <span>{{ details.name?details.name:'---' }}</span>
            </el-form-item>
            <el-form-item label="法人：" label-width="120px">
              <span v-if="!!company">{{ company.corporateName?company.corporateName:'---' }}</span>
            </el-form-item>
            <el-form-item label="企业类型：" label-width="120px">
              <span v-if="!!company">{{ company.extend.enterpriseTyeName?company.extend.enterpriseTyeName:'---' }}</span>
            </el-form-item>
            <el-form-item label="经营范围：" label-width="120px">
              <span v-if="!!company">{{ companyRange?companyRange:'---' }}</span>
            </el-form-item>
            <el-form-item label="开通时间：" label-width="120px">
              <span>{{ details.addTime?details.addTime:'---' }}</span>
            </el-form-item>
            <el-form-item label="店铺位置：" label-width="120px">
              <span>{{ details.address?details.address:'---' }}</span>
            </el-form-item>
            <el-form-item label="运营商" label-width="120px">
              <span>{{ details.operator?details.operator:'---' }}</span>
            </el-form-item>
            
            <el-form-item label="店铺营业时间：" label-width="120px" v-if="false">
              <span>{{ details.bizHours?details.bizHours:'---' }}</span>
            </el-form-item>
            <el-form-item label="店铺客服：" label-width="120px" class="serveImg" v-if="false">
              <p><img src="@/assets/images/qq.svg" alt="">&nbsp;&nbsp;&nbsp;{{ details.qq?details.qq:'---' }}</p>
              <p><img src="@/assets/images/weChat.svg" alt="">&nbsp;&nbsp;&nbsp;{{ details.wx?details.wx:'---' }}</p>
              <p><img src="@/assets/images/phone.svg" alt="">&nbsp;&nbsp;&nbsp;{{ details.phone?details.phone:'---' }}</p>
            </el-form-item>
            <el-form-item label-width="120px">
              <el-button type="success" style="width:150px;" @click="self_edit">编辑</el-button>
            </el-form-item>
          </el-form>

          <el-form 
          v-show="Editor" 
          :model="formInfo" 
          label-width="200px">
            <el-form-item label="店铺名称：" label-width="120px">
              <el-input v-model="details.name" disabled placeholder="请输入店铺名称" clearable class="edit_input"></el-input>
            </el-form-item>
            <el-form-item label="法人：" label-width="120px">
              <el-input v-if="!!company" disabled placeholder="请输入法人姓名" v-model="company.corporateName" clearable class="edit_input"></el-input>
            </el-form-item>
            <el-form-item label="企业类型：" label-width="120px" prop="name">
              <el-input v-if="!!company" disabled class="edit_input" v-model="company.extend.enterpriseTyeName"></el-input>
            </el-form-item>
            <el-form-item label="经营范围：" label-width="120px" prop="name">
              <el-input disabled v-model="companyRange" placeholder="请输入经营范围" clearable class="edit_input"></el-input>
            </el-form-item>
            <el-form-item label="开通时间：" label-width="120px" prop="name">
              <el-input v-model="details.addTime" disabled clearable class="edit_input"></el-input>
            </el-form-item>
            <el-form-item label="店铺位置：" label-width="120px" prop="address">
              <el-input v-model="form.address" icon="el-icon-location" clearable placeholder="请输入店铺位置">
                <el-button slot="append" icon="el-icon-location" style="width:70px; font-size:25px; color:#28B892; vertical-align:middle;" @click="cVisible = true"></el-button>
              </el-input>
              <div slot="prepend" style="width:100px">
                <i class="el-icon-location"  @click="cVisible = true" style="width:100px;margin-left:30px; font-size:30px; color:#28B892; vertical-align:middle;"></i>
              </div>
            </el-form-item>
            <el-form-item label="运营商类型：" label-width="120px" prop="operator">
              <el-select v-model="operator"  placeholder="请选择运营商类型" clearable filterable :multiple="url == -1">
                <el-option label="中国移动" value="中国移动"></el-option>
                <el-option label="中国联通" value="中国联通"></el-option>
                <el-option label="中国电信" value="中国电信"></el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item label="请确认省/市/区：" label-width="125px">
              <el-cascader v-model="valueA" :options="dataTreeB" accordion :props="{ ...defaultPropsB }" @change="handleNodeClick" clearable ref="cascader" style="width: 100%;"></el-cascader>
            </el-form-item>
            <el-form-item label="店铺营业时间："  label-width="120px" prop="name">
              <el-time-picker style="width:100%;" is-range v-model="clockRange" range-separator="-"
                value-format="HH:mm" format="HH:mm" start-placeholder="开始时间" end-placeholder="结束时间" :picker-options="clockOptions" @change="getClockRange($event,'startClock','endClock')"
                placeholder="请选择店铺营业时间">
              </el-time-picker>
            </el-form-item>
            <el-form-item label="店铺客服：" label-width="120px" prop="name" >
              <el-input v-model="form.qq" clearable  placeholder="请输入店铺客服QQ">
                <template slot="prepend"><img class="img1" src="@/assets/images/qq.svg" alt=""></template>
              </el-input>
            </el-form-item>
            <el-form-item label-width="120px" prop="name">
              <el-input v-model="form.wx" clearable  placeholder="请输入店铺客服微信">
                <template slot="prepend"><img class="img1" src="@/assets/images/weChat.svg" alt=""></template>
              </el-input>
            </el-form-item>
            <el-form-item label-width="120px" prop="name">
              <el-input v-model="form.phone" clearable  placeholder="请输入店铺客服手机号">
                <template slot="prepend"><img class="img1" src="@/assets/images/phone.svg" alt=""></template>
              </el-input>
            </el-form-item>
            
            <el-form-item label-width="120px">
              <el-button type="success" style="width:80px;" @click="cancel_info">取消</el-button>
              <el-button type="success" style="width:80px;" @click="save_info">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    
    <el-dialog title="经纬度选择" :visible.sync="cVisible" fullscreen :close-on-click-modal="false">
      <div class="map-container" v-if="cVisible">
        <maps :lng="parseFloat(form.longitude)" :lat="parseFloat(form.latitude)" @finish="setLngLat"></maps>
      </div>
    </el-dialog>

    <div v-if="0">
      <div class="bold-title" v-if="1">支付认证</div>
      <div class="content-pay" v-if="1">
        <p>店铺已开通，请完成支付宝认证</p>
        <p>请用支付宝，支付 <el-input size="mini" type="number" clearable></el-input> 元</p>
        <el-button type="primary" @click="goPay">去支付</el-button>
      </div>
      <div class="bold-title" v-if="1">缴纳押金</div>
      <div class="content-pay" v-if="1">
        <p>支付宝认证已完成，请缴纳押金</p>
        <p>保证金金额 <el-input size="mini" type="number" clearable></el-input> 元</p>
        <p>增值服务费</p>
        <p>合计支付：1000元</p>
        <el-button type="primary" @click="goPay">去支付</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import clockPicker from "@/mixins/clockPicker";
import datePicker from "@/mixins/datePicker";
import Maps from '@/components/common/Maps';
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name: "storeInfo",
  components: {
    Maps,
    BaseUpload,
  },
  mixins: [ pageMixin, datePicker, clockPicker ],
  data() {
    return {
      url:null,
      valueA: [],
      dataTreeB: [],
      defaultPropsB: {
        children: "children",
        label: "name",
        value: "id",
      },
      clockRange: '',
      detail_info: {},
      formInfo: {},
      storeOption: [],
      componyOption: [],
      details: {},
      company: {
        extend: {}
      },
      companyRange: '',
      form: {
        id: null,
        bizHours: '',
        startClock: '',
        endClock: '',
        adress: '',
        qq: '',
        photo: '',
        wx: '',
        phone: '',
        status: null,
        latitude: null,
        longitude: null,
        province: null,
        city: null,
        area: null,
      },
      // 运营商类型
      operator:[],
      Editor: false,
    };
  },
  created() {
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033') || 
    console.log('url',this.url)
  },
  methods: {
    ...mapActions("storeInfo",["storeInfoLoad", "storeInfoEdit", 'getSchoolAreaTree']),
    ...mapActions("commonOptions",["commonDictGetDictFPid"]),
    handleNodeClick(data) {
      // console.log(this.valueA)
      data.forEach(ele=>{
        if ((ele + "").length === 9) { this.province = ele }
        if ((ele + "").length === 12) { this.city = ele  }
        if ((ele + "").length === 15) { this.area = ele  }
        this.form.province = this.province
        this.form.city = this.city
        this.form.area = this.area
      })
    },
    async self_edit() {
      this.form.address = this.details.address
      this.form.photo = this.details.photo
      if(this.details.operator)this.operator = this.details.operator.split(',')
      this.clockRange = [ this.form.startClock, this.form.endClock ]
      this.Editor = true
      // 企业类型
      this.componyOption = await this.commonDictGetDictFPid({pid:104002})
      // 商铺类型
      this.storeOption = await this.commonDictGetDictFPid({pid:104003})
      // this.clockRangeChange(this.form.bizHours)
      // console.log(this.componyOption, this.storeOption)
    },
    save_info() {
      this.form.id = sessionStorage.getItem('shopId')-0
      this.form.bizHours = this.form.startClock+'-'+this.form.endClock
      if (this.url == -1) {
        this.form.operator = this.operator.join()
      }else{
        this.form.operator = this.operator
      }
      delete this.form.endClock
      delete this.form.startClock 
      try{
        this.storeInfoEdit({...this.form})
        this.$message.success("保存成功")
      }catch(error){}
      this.infoLoad()
      this.Editor = false
    },
    cancel_info() {this.Editor=false},
    goPay() { this.$router.push({path: 'storePay'}) },
    clockRangeChange(val, index){
      this.gameClock[index].startClock = val ? val[0] : ''
      this.gameClock[index].endClock = val ? val[1] : ''
      // this.form.bizHours = this.gameClock[index].startClock + '-' + this.gameClock[index].endClock
    },
    // 图片上传
    handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png') || file.name.includes('gif');
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isPicture) { this.$message.error('上传图片只能是 JPG/PNG/gif 格式!'); }
      if (!isLt2M) { this.$message.error('上传图片大小不能超过 5MB!'); }
      callback(isPicture && isLt2M)
    },
    async infoLoad() {
      try{
        this.companyRange = ''
        let id = sessionStorage.getItem('shopId')
        let res = await this.storeInfoLoad({id: id-0})
        this.valueA = [res.province,res.city,res.area]
        if(!res){
          this.$nextTick(async ()=>{
            let id = sessionStorage.getItem('shopId')
            let res = await this.storeInfoLoad({id: id-0}) 
            this.company = res.company
            this.details = res
            let arr = res.bizHours.split('-')
            this.form.startClock = arr[0]
            this.form.endClock = arr[1]
            this.form.qq = res.qq
            this.form.adress = res.adress
            this.form.latitude = res.adress
            this.form.longitude = res.adress
            this.form.wx = res.wx
            this.form.phone = res.phone
            this.form.province = res.province
            this.form.area = res.city
            this.form.city = res.phone
            this.form.status = this.details.status
            if(!!res.company.scopeName){
              res.company.scopeName.forEach((element,index) => { this.companyRange += element + ('、') });
              this.companyRange = this.companyRange.slice(0,this.companyRange.length-1)
            }
          });
        }
        this.company = res.company
        this.details = res
        let arr = res.bizHours.split('-')
        this.form.startClock = arr[0]
        this.form.endClock = arr[1]
        this.form.qq = res.qq
        this.form.adress = res.adress
        this.form.latitude = res.adress
        this.form.longitude = res.adress
        this.form.wx = res.wx
        this.form.phone = res.phone
        this.form.status = this.details.status
        this.form.province = res.province
        this.form.area = res.city
        this.form.city = res.phone
        if(!!res.company.scopeName){
          res.company.scopeName.forEach((element,index) => { this.companyRange += element + ('、') });
          this.companyRange = this.companyRange.slice(0,this.companyRange.length-1)
        }
      }catch(error){}
    },
    setLngLat(val){
      this.form.longitude = val[0]
      this.form.latitude = val[1]
      this.form.address = val[2]
      this.cVisible = false
    },
    cancel() {
      this.form.latitude = ''
      this.aVisible = false
    }
  },
  async mounted() {
    this.dataTreeB = await this.getSchoolAreaTree({code: "area_cn"})
    this.infoLoad()
  }
};
</script>
<style scoped lang="scss">
.bold-title{ width: 100%; border-bottom: 1px solid #999; font-size: 20px; font-weight: 600; margin-bottom: 20px; }
.content-pay { 
  .el-input { width: 100px; }
  /deep/.el-input--suffix .el-input__inner {padding-right: 0;}
}
.serveImg { 
  p{
    img{ vertical-align: baseline; }
  }
}
</style>
